import React from "react";
import { Pagination } from "@mui/material";
import { Tooltip, tooltipClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import moment from "moment";
import CopyToClipboard from "react-copy-to-clipboard";
import { reactIcons } from "../../utils/icons";
import { toast } from "react-toastify";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    color: "white", // Tooltip text color
    background:
      "linear-gradient(292deg, #ff0000 0%, #000000 75%, #000000 100%)",
    fontSize: "16px",
    padding: "16px",
  },
});
const WalletAddressTable = ({
  handleNavigate,
  headings,
  pageNumber,
  listData,
  handlePage,
  take,
  setPageNumber,
}) => {
  const toolTipTitle =
    "Node ID indicates which validator node you own fractions of.";
  // copy wallet address function
  const handleCopy = () => {
    toast.success("Copied Successfully");
  };
  return (
    <>
      <div className="overflow-x-auto custom-scroll opacity-[100%] duration-1000">
        <table className="text-xs leading-6 font-normal min-w-[1290px] w-full">
          <thead>
            <tr className="bg-gradient-red rounded-lg text-white">
              {headings.map((item, index) => {
                return (
                  <th key={item} className="group">
                    <div
                      className={`py-1 my-1 flex items-center justify-center text-left font-semibold ${
                        index === headings.length - 1
                          ? "border-r-0"
                          : "border-white border-r"
                      }`}
                    >
                      {item}
                      {item === "Node ID" && (
                        <CustomTooltip title={toolTipTitle}>
                          <span
                            className="ml-3 cursor-pointer text-center text-14 font-semibold text-white 
  w-[23px] h-[23px] flex items-center justify-center 
  hover:bg-white hover:text-black hover:rounded-full transition-all duration-300"
                          >
                            !
                          </span>
                        </CustomTooltip>
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {listData.data?.length > 0 ? (
              listData?.data &&
              listData?.data?.map((item, index) => {
                return (
                  <tr
                    className=" bg-[#181B1D] h-[42px] border-2 border-[#393939] text-white mb-2"
                    key={index}
                  >
                    <td>
                      <div className="text-center border-r border-white">
                        {(pageNumber - 1) * take + index + 1}
                      </div>
                    </td>
                    <td>
                      <div className="text-center border-r border-white">
                        {item?.userEmail}
                      </div>{" "}
                    </td>
                    <td>
                      <div className="text-center border-r border-white">
                        {item?.userMobile}
                      </div>{" "}
                    </td>
                    <td>
                      <div className="text-center border-r border-white">
                        {moment(item?.payoutCreatedAt).format(
                          "YYYY-MM-DD hh:mm A"
                        )}
                      </div>{" "}
                    </td>

                    <td>
                      <div className="text-center">
                        <div className=" flex items-center justify-center gap-2 p-2.5 rounded-lg ">
                          <div>
                            {item?.walletAddress.slice(0, 6) +
                              "..." +
                              item?.walletAddress.slice(38, 42)}
                          </div>
                          <CopyToClipboard
                            text={item?.walletAddress}
                            onCopy={handleCopy}
                          >
                            <button type="button"> {reactIcons.copy}</button>
                          </CopyToClipboard>
                        </div>
                      </div>{" "}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={5} className="text-white text-center py-4">
                  Not Available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="py-4 flex justify-end items-center">
        <Pagination
          count={Math.ceil(parseInt(listData.count / take))}
          onChange={(e, page) => {
            handlePage(e, page);
            setPageNumber(page);
          }}
          page={pageNumber}
          hideNextButton={
            pageNumber >= Math.ceil(parseInt(listData.count / take))
          }
          hidePrevButton={pageNumber <= 1}
          sx={{
            "& .MuiPaginationItem-root": {
              background: "transparent",
              color: "white",
              "&:focus": {
                background: "none",
                outline: "none",
                boxShadow: "none",
              },
            },
            "& .MuiPaginationItem-previousNext": {
              background:
                "linear-gradient(292deg, #ff0000 0%, #000000 75%, #000000 100%)",
              color: "white",
              "&:hover": {
                background:
                  "linear-gradient(292deg, #ff0000 0%, #000000 75%, #000000 100%)",
              },
              "&:focus": {
                background:
                  "linear-gradient(292deg, #ff0000 0%, #000000 75%, #000000 100%)",
                outline: "none",
                boxShadow: "none",
              },
            },
            "& .Mui-selected": {
              background:
                "linear-gradient(292deg, #ff0000 0%, #000000 75%, #000000 100%)",
              color: "#fff",
              fontWeight: "bold",
              borderRadius: "50%",

              "&:focus": {
                background:
                  "linear-gradient(292deg, #ff0000 0%, #000000 75%, #000000 100%)",
                outline: "none",
                boxShadow: "none",
              },
            },
          }}
        />
      </div>
    </>
  );
};
export default WalletAddressTable;
