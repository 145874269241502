export const mstTestnet = {
  id: 0x11c1,
  name: "MST Testnet",
  // network: "mst-testnet",
  nativeCurrency: {
    name: "tMST",
    symbol: "tMST",
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ["https://testnetrpc.mstblockchain.com"],
    },
  },
  blockExplorers: {
    default: {
      name: "MST Explorer",
      url: "https://testnet.mstscan.com",
      apiUrl: "https://testnet.mstscan.com",
    },
  },

  testnet: true,
};

export const mstMainnet = {
  id: 0x11c2,
  name: "MST Mainnet",
  // network: "mst-mainnet",
  nativeCurrency: {
    name: "MSTC",
    symbol: "MSTC",
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ["https://rpc.mstblockchain.com"],
    },
  },
  blockExplorers: {
    default: {
      name: "MST Explorer",
      url: "https://mstscan.com",
      apiUrl: "https://mstscan.com",
    },
  },
  testnet: false,
};

export const currentNetwork =
  process.env.REACT_APP_NETWORK === "testnet" ? mstTestnet : mstMainnet;
