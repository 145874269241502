import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddressModal from "../modals/AddressModal";

import { toast } from "react-toastify";
import { patchReq, postAuthReq } from "../../utils/apiHandlers";
import { fetchUserData } from "../../redux/Slice/User/user";
import { isYupError, parseYupError } from "../../utils/Yup";
import { walletAddress } from "../../utils/validation";
import UpdateAddressModal from "../modals/UpdateAddressModal";

const WalletAddUpdate = ({ handleAddressLogs }) => {
  const { userData } = useSelector((state) => state?.user || {});
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    walletAddress: "",
  });
  const [formError, setFormError] = useState({
    walletAddress: "",
  });

  // input value update function
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  // add wallet address function
  const addWalletAddress = async () => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      await walletAddress.validate(form, {
        abortEarly: false,
      });
      let payload = {
        walletAddress: form.walletAddress,
      };
      const res = await patchReq("/users/me", payload);
      const { status, data } = res;
      if (status) {
        toast.success(data.status);
        setIsOpen(false);
        dispatch(fetchUserData());
        setForm({
          walletAddress: "",
        });
        setFormError({
          walletAddress: "",
        });
      } else {
        toast.error(res?.error?.message[0]);
      }
    } catch (error) {
      if (isYupError(error)) {
        setFormError(parseYupError(error));
      }
    } finally {
      setIsLoading(false);
    }
  };

  // update wallet address function
  const hanldeUpdateWallet = async () => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      await walletAddress.validate(form, {
        abortEarly: false,
      });
      let payload = {
        walletAddress: form.walletAddress,
      };
      const res = await postAuthReq("/wallet-address/update-request", payload);
      const { status, error } = res;
      console.log(res, "res");
      if (status) {
        setIsLoading(false);
        setOpen(false);
        toast.success("Request Created Successfully");
        setForm({
          walletAddress: "",
        });
        setFormError({
          walletAddress: "",
        });
      } else {
        toast.error(error?.message);
        setIsLoading(false);
      }
    } catch (error) {
      if (isYupError(error)) {
        setFormError(parseYupError(error));
        setIsLoading(false);
      } else {
        toast.error(error);
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      {userData?.meta?.walletAddress !== null ? (
        <div className="mb-5">
          <button
            type="button"
            onClick={() => setOpen(true)}
            className="gradient-background text-white py-2.5 px-6 rounded-lg flex items-center justify-center gap-2 border relative text-10 md:text-12 font-normal w-full mt-5 "
          >
            Change Wallet Address
          </button>
          <button
            type="button"
            onClick={handleAddressLogs}
            className="w-full text-right text-[11px] text-[#ee0000] underline mt-2"
          >
            View Wallet Address Logs
          </button>
        </div>
      ) : (
        <button
          type="button"
          onClick={() => setIsOpen(true)}
          className="gradient-background text-white py-2.5 px-6 rounded-lg flex items-center justify-center gap-2 border relative text-10 md:text-12 font-normal w-full mt-5 mb-5"
        >
          Add Wallet Address
        </button>
      )}
      <AddressModal
        setOpen={setIsOpen}
        open={isOpen}
        isLoading={isLoading}
        formError={formError}
        handleChange={handleChange}
        addWalletAddress={addWalletAddress}
      />
      <UpdateAddressModal
        setOpen={setOpen}
        open={open}
        isLoading={isLoading}
        formError={formError}
        handleChange={handleChange}
        hanldeUpdateWallet={hanldeUpdateWallet}
      />
    </>
  );
};

export default WalletAddUpdate;
