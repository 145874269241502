import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/header/Header";
import { useLocation } from "react-router-dom";
import VerifyModal from "../components/modals/VerifyModal";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import PublicSidebar from "./PublicSidebar";
import Footer from "../components/Footer";

const DefaultDashboard = () => {
  const [isOpen, setIsOpen] = useState(window.innerWidth > 1024 ? false : true);
  const [openVerifyKyc, setOpenVerifyKyc] = useState(true);
  const location = useLocation();
  const getToken = Cookies.get("accessToken");
  const { userData } = useSelector((state) => state?.user || {});

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1024) {
        setIsOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     await dispatch(fetchUserData());
  //     setLoading(false);
  //   };

  //   fetchData();
  // }, [dispatch]);

  const isVerify = userData?.isVerified;
  const toggleSidebar = () => {
    setIsOpen((prevState) => !prevState);
  };
  return (
    <>
      <div className="overflow-hidden min-h-[100dvh]">
        <Header
          toggleSidebar={toggleSidebar}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          isVerify={isVerify}
          userData={userData}
          getToken={getToken}
        />
        <div
          className={`h-[calc(100dvh-80px)] flex ${isOpen ? "side-open" : ""}`}
        >
          <div
            className={`absolute md:relative ${
              !isOpen
                ? "top-[80px] md:top-0 bottom-0 left-0 transition-all duration-75 w-[240px] md:w-[230px] lg:w-[280px]"
                : "sm:w-[0px] lg:w-[60px] transition-all duration-75"
            } z-20 lg:border-r lg:border-r-white`}
          >
            <PublicSidebar
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              isVerify={isVerify}
              getToken={getToken}
            />
          </div>
          <div
            className={`md:flex-1 bg-[#0F0F0F] overflow-hidden relative z-10 w-full ${
              !isOpen && "sm:blur-sm md:blur-0"
            } ${isOpen ? "blur-0" : "md:blur-0"}`}
          >
            <div className="overflow-auto h-full p-8 px-3 lg:px-12">
              <Outlet />
              <Footer />
            </div>
            <div className="fixed right-0 bottom-0 w-full z-[-2]">
              <img
                src="/images/wave.png"
                alt="Wave background"
                className="w-full"
              />
            </div>

            {location.pathname !== "/" && (
              <>
                <div className="absolute right-[50px] bottom-[40px] z-[-1]">
                  <img
                    src="/images/outlit/bigbox.png"
                    alt="Wave background"
                    className="w-[85px] opacity-[0.2] lg:opacity-100"
                  />
                </div>
                <div className="absolute right-[165px] bottom-[112px] z-[-1]">
                  <img
                    src="/images/outlit/smallbox.png"
                    alt="Wave background"
                    className="w-[50px] opacity-[0.2] lg:opacity-100"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {!isVerify && getToken && (
        <VerifyModal open={openVerifyKyc} setOpen={setOpenVerifyKyc} />
      )}
    </>
  );
};

export default DefaultDashboard;
