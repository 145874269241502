import React from "react";
import Spinner from "../components/loaders/Spinner";
import loadable from "../utils/loadable";

// Dashboard
export const Dashboard = loadable(() => import("./Dashboard"), {
  fallback: <Spinner />,
});

// calculator
export const GrowthCalculater = loadable(() => import("./GrowthCalculater"), {
  fallback: <Spinner />,
});

// Kyc
export const Kyc = loadable(() => import("./Kyc"), {
  fallback: <Spinner />,
});

// Withdraw
export const Withdraw = loadable(() => import("./Withdraw"), {
  fallback: <Spinner />,
});

// Report
export const Report = loadable(() => import("./Report"), {
  fallback: <Spinner />,
});

// PurchaseNodes
export const PurchaseNodes = loadable(() => import("./PurchaseNodes"), {
  fallback: <Spinner />,
});

// Login
export const Login = loadable(() => import("./Login"), {
  fallback: <Spinner />,
});

// RegisterPage
export const RegisterPage = loadable(() => import("./Register/RegisterPage"), {
  fallback: <Spinner />,
});

// ForgotPassword
export const ForgotPassword = loadable(() => import("./ForgotPassword"), {
  fallback: <Spinner />,
});

// Purchase
export const Purchase = loadable(() => import("./Purchase"), {
  fallback: <Spinner />,
});

// AffiliateList
export const AffiliateList = loadable(() => import("./Affiliate"), {
  fallback: <Spinner />,
});

// Franchise
export const Franchise = loadable(() => import("./Franchise/Franchise"), {
  fallback: <Spinner />,
});
// Payout
export const Payout = loadable(() => import("./Payout"), {
  fallback: <Spinner />,
});
// About Page
export const About = loadable(() => import("./About"), {
  fallback: <Spinner />,
});
// Privacy Page
export const Privacy = loadable(() => import("./Privacy"), {
  fallback: <Spinner />,
});
// Terms Page
export const Terms = loadable(() => import("./Terms"), {
  fallback: <Spinner />,
});
// Cancellation Page
export const Cancellation = loadable(() => import("./Cancellation"), {
  fallback: <Spinner />,
});
// Shipping Page
export const Shipping = loadable(() => import("./Shipping"), {
  fallback: <Spinner />,
});

// Contact Page
export const Contact = loadable(() => import("./Contact"), {
  fallback: <Spinner />,
});

// Coin Earn Page
export const CoinEarn = loadable(() => import("./CoinEarn"), {
  fallback: <Spinner />,
});

// Fractions Earn Page
export const FractionsEarn = loadable(() => import("./FractionsEarn"), {
  fallback: <Spinner />,
});

// Fractions Page
export const Fractions = loadable(() => import("./Fractions"), {
  fallback: <Spinner />,
});

// NotFound
export const NotFound = loadable(() => import("./NotFound"), {
  fallback: <Spinner />,
});
