import React, { useState } from "react";
import { reactIcons } from "../../utils/icons";
import ReportNodesModal from "../modals/ReportNodesModal";
import { getAuthReq } from "../../utils/apiHandlers";
import TotalEarnedCoinModal from "../modals/TotalEarnedCoinModal";
import { Link } from "react-router-dom";
const Card = ({ data, userData }) => {
  const cardData = [
    {
      title: "Fraction Owned",
      amount: (data && data?.fractionOwned) || "0",
    },
    {
      title: "Total Coin Earned",
      amount: (data && data?.totalCoinEarnedForAdmin) || "0",
    },
    {
      title: "Total Validated Block",
      amount: (data && data?.totalValidatedBlock) || "0",
    },
    {
      title: "Average Block Time",
      amount: (data && data?.AverageBlockTimeInSecond + " Sec") || "0",
    },
  ];

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [reportValidation, setReportValidation] = useState([]);

  const fetchReportValidateFraction = async () => {
    const res = await getAuthReq(
      `/reports/validated-fractions?userId=${userData && userData?.id}`
    );
    const { status, data } = res;
    if (status) {
      setReportValidation(data);
    }
  };

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-6 mt-4 mb-4">
        {cardData &&
          cardData?.map((item, index) => {
            return (
              <div
                key={index}
                className={
                  "max-w-full overflow-hidden  bg-gradient-2 shadow-lg py-4 px-8 rounded-[20px] cursor-pointer hover:border-b-4 hover:border-b-red-600 transition transform hover:-translate-y-1"
                }
              >
                <div className="py-2">
                  {item.title === "Total Coin Earned" ? (
                    <Link
                      to={"/coin-earn"}
                      className="font-bold text-18 lg:text-2xl text-white mb-2 text-left flex items-center justify-between"
                    >
                      {item.title}
                      <span
                        className="text-16 lg:text-18"
                        onClick={() => {
                          setOpen1(true);
                        }}
                      >
                        {reactIcons.info}
                      </span>
                    </Link>
                  ) : (
                    <h5 className="font-bold text-18 lg:text-2xl text-white mb-2 text-left flex items-center justify-between">
                      {item.title}
                      {index === 0 && (
                        <span
                          className="text-16 lg:text-18"
                          onClick={() => {
                            fetchReportValidateFraction();
                            setOpen(true);
                          }}
                        >
                          {reactIcons.info}
                        </span>
                      )}
                    </h5>
                  )}
                </div>
                <div className="flex justify-end">
                  <p className="break-all font-semibold text-26 lg:text-[30px] text-white flex items-center gap-2">
                    {item.amount}
                  </p>
                </div>
              </div>
            );
          })}
      </div>
      <ReportNodesModal
        setOpen={setOpen}
        open={open}
        reportValidationData={reportValidation.data}
      />
      <TotalEarnedCoinModal setOpen1={setOpen1} open1={open1} coinData={data} />
    </>
  );
};
export default Card;
