import React from 'react';
import { CircularProgress } from '@mui/material';

const Loader = () => {
  return (
    <div className="">
      <CircularProgress size={16} />
    </div>
  );
};
export default Loader;
